import React, { Component } from 'react';

export class Footer extends Component {
  render() {
      return (
        <div>
            <section class="info_section layout_padding2">
                  <div class="container">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="detail">
                                  <h5>
                                      Join Us on Our Journey
                                  </h5>
                                  <p>
                                      We invite you to join us on our journey as we continue to push the boundaries of empowering businesses to achieve their full potential. Together, let's build a future where every business can cultivate meaningful relationships and drive sustainable growth.
                                  </p><br />
                                  <h5>
                                      Get in Touch
                                  </h5>
                                  <p>
                                      Ready to embark on your business journey with TaraCode? Contact us today to learn more about how we can help your business thrive in the digital age.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="container layout_padding">
                      <div class="row">
                          <div class="col-md-6 col-lg-3 info_col">
                              <div class="info_contact">
                                  <h4>
                                      Contact Information
                                  </h4>
                                  <ul class="icon-list-items">
                                      <li class=".icon-list-item">
                                          <span class="icon-list-icon">
                                              <i class="fa fa-map-marker" aria-hidden="true"></i>
                                          </span>
                                          <span class="icon-list-text">
                                              Metro Manila, Philippines
                                          </span>
                                      </li>
                                      <li class=".icon-list-item">
                                          <span class="icon-list-icon">
                                              <i class="fa fa-phone" aria-hidden="true"></i>
                                          </span>
                                          <span class="icon-list-text">
                                              +63 9433780828
                                          </span>
                                      </li>
                                      <li class=".icon-list-item">
                                          <span class="icon-list-icon">
                                              <i class="fa fa-envelope" aria-hidden="true"></i>
                                          </span>
                                          <span class="icon-list-text">
                                              support@tarcode.com
                                          </span>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <div class="col-md-6 col-lg-2 mx-auto info_col">
                              <div class="info_link_box">
                                  <h4>
                                      Links
                                  </h4>
                                  <div class="info_links">
                                      <a class="active" href="index.html">
                                          Home
                                      </a>
                                      <a class="" href="about.html">
                                          About
                                      </a>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-6 col-lg-3 info_col ">
                              <h4>
                                  Subscribe
                              </h4>
                              <form action="#">
                                  <input type="text" placeholder="Enter email" />
                                  <button type="submit">
                                      Subscribe
                                  </button>
                              </form>
                          </div>
                      </div>
                  </div>
              </section>
              <section class="footer_section">
                  <div class="container">
                      <p>
                          &copy; <span id="displayYear"></span> All Rights Reserved By TaraCode Inc
                      </p>
                  </div>
              </section>
        </div>
    );
  }
}
