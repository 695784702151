import React, { Component } from 'react';

export class AppLayout extends Component {
    static displayName = AppLayout.name;

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}
