import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';

export class Layout extends Component {
  static displayName = Layout.name;
  render() {
    return (
      <div>
        <div class="hero_area">
            <NavMenu />
            {this.props.children}
        </div>
        <Footer/>
      </div>
    );
  }
}

