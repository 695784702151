import React, { Component } from 'react';

export class Contact extends Component {
    static displayName = Contact.name;

  render() {
      return (
      <div>
        <section class="slider_section ">
                <div id="customCarousel1" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="container ">
                                <div class="row">
                                    <div class="col-md-6 ">
                                        <div class="detail-box">
                                            <h1>
                                                CRM <br />
                                                SOLUTIONS
                                            </h1>
                                            <p>
                                                Unlock the Power of Relationships with Our Cutting-Edge CRM Solutions!
                                            </p>
                                            <p>
                                                Discover seamless integration, intuitive interfaces, and robust features designed to transform how you engage with your audience. Revolutionize your approach to customer management and experience the difference with TaraCode CRM.
                                            </p>
                                            <div class="btn-box">
                                                <a href="" class="btn1">
                                                    Read More
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="img-box">
                                            <img src="images/slider-img.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item ">
                            <div class="container ">
                                <div class="row">
                                    <div class="col-md-6 ">
                                        <div class="detail-box">
                                            <h1>
                                                Crypto <br />
                                                Currency
                                            </h1>
                                            <p>
                                                Explicabo esse amet tempora quibusdam laudantium, laborum eaque magnam fugiat hic? Esse dicta aliquid error repudiandae earum suscipit fugiat molestias, veniam, vel architecto veritatis delectus repellat modi impedit sequi.
                                            </p>
                                            <div class="btn-box">
                                                <a href="" class="btn1">
                                                    Read More
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="img-box">
                                            <img src="images/slider-img.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="container ">
                                <div class="row">
                                    <div class="col-md-6 ">
                                        <div class="detail-box">
                                            <h1>
                                                Crypto <br />
                                                Currency
                                            </h1>
                                            <p>
                                                Explicabo esse amet tempora quibusdam laudantium, laborum eaque magnam fugiat hic? Esse dicta aliquid error repudiandae earum suscipit fugiat molestias, veniam, vel architecto veritatis delectus repellat modi impedit sequi.
                                            </p>
                                            <div class="btn-box">
                                                <a href="" class="btn1">
                                                    Read More
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="img-box">
                                            <img src="images/slider-img.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ol class="carousel-indicators">
                        <li data-target="#customCarousel1" data-slide-to="0" class="active"></li>
                        <li data-target="#customCarousel1" data-slide-to="1"></li>
                        <li data-target="#customCarousel1" data-slide-to="2"></li>
                    </ol>
                </div>
                </section>
        <section class="service_section layout_padding">
                <div class="service_container">
                    <div class="container ">
                        <div class="heading_container heading_center">
                            <h2>
                                Our <span>Services</span>
                            </h2>
                            <p>
                                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
                            </p>
                        </div>
                        <div class="row">
                            <div class="col-md-4 ">
                                <div class="box ">
                                    <div class="img-box">
                                        <img src="images/s1.png" alt="" />
                                    </div>
                                    <div class="detail-box">
                                        <h5>
                                            Currency Wallet
                                        </h5>
                                        <p>
                                            fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                            The
                                            point of using
                                        </p>
                                        <a href="">
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 ">
                                <div class="box ">
                                    <div class="img-box">
                                        <img src="images/s2.png" alt="" />
                                    </div>
                                    <div class="detail-box">
                                        <h5>
                                            Security Storage
                                        </h5>
                                        <p>
                                            fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                            The
                                            point of using
                                        </p>
                                        <a href="">
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 ">
                                <div class="box ">
                                    <div class="img-box">
                                        <img src="images/s3.png" alt="" />
                                    </div>
                                    <div class="detail-box">
                                        <h5>
                                            Expert Support
                                        </h5>
                                        <p>
                                            fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                            The
                                            point of using
                                        </p>
                                        <a href="">
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn-box">
                            <a href="">
                                View All
                            </a>
                        </div>
                    </div>
                </div>
            </section>
      </div>
    );
  }
}
