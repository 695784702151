import React, { Component } from 'react';

export class Login extends Component {
    static displayName = Login.name;
    render() {
        return (
            <div class="login-page">
                <div class="login-box">
                  <div class="login-logo">
                    <b>TARACODE</b>IAMS
                  </div>
                  <div class="card">
                    <div class="card-body login-card-body">
                      <p class="login-box-msg">Sign in to start your session</p>

                      <form action="../../index3.html" method="post">
                        <div class="input-group mb-3">
                          <input type="email" class="form-control" placeholder="Email" />
                          <div class="input-group-append">
                            <div class="input-group-text">
                              <span class="fas fa-envelope"></span>
                            </div>
                          </div>
                        </div>
                        <div class="input-group mb-3">
                          <input type="password" class="form-control" placeholder="Password" />
                          <div class="input-group-append">
                            <div class="input-group-text">
                              <span class="fas fa-lock"></span>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div class="social-auth-links text-center mb-3">
                        <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                        <button type="submit" class="btn btn-danger btn-block">I forgot my password</button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        );
    };
}