import React, { Component } from 'react';

export class About extends Component {
  static displayName = About.name;

  render() {
      return (
        <div>
         <section class="about_section layout_padding">
            <div class="container  ">
                <div class="heading_container heading_center">
                    <h2>
                        About <span>Us</span>
                    </h2>
                    <p>
                              Welcome to TaraCode, your ALL-IN-ONE Store Solution. At TaraCode, we understand the challenges that store owners face in managing their operations efficiently while providing exceptional service to their customers. That's why we've developed a comprehensive suite of solutions designed to streamline your store's processes and help you succeed in today's competitive market.
                    </p>
                </div>
                <div class="row">
                    <div class="col-md-6 ">
                        <div class="img-box">
                            <img src="images/about-img.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-6">
                          <div class="detail-box">
                            <h3>
                                Our Journey
                            </h3>
                            <p>
                                TaraCode was founded with a bold ambition: to empower businesses, both big and small, with the tools they need to thrive in an increasingly competitive marketplace. Our journey began with a team of dedicated individuals who shared a common passion for technology and a deep understanding of the evolving needs of modern businesses.
                            </p><br />     
                            
                            <h3>
                                Our Mission
                            </h3>
                            <p>
                                Our mission at TaraCode is simple yet profound: to provide cutting-edge solutions that enable businesses of all sizes to thrive in today's competitive landscape. We aim to empower organizations with the tools they need to streamline their operations, deepen customer engagement, and drive sustainable growth.
                            </p><br />     
                            <h3>
                                  Our Commitment
                            </h3>
                            <p>
                                  At TaraCode, we believe that strong relationships are the foundation of success. That's why we are committed to building lasting partnerships with our clients based on trust, transparency, and mutual respect. We are not just a service provider; we are your trusted advisor, guiding you every step of the way on your journey towards excellence.
                            </p><br /> 
                        </div>
                    </div>
                </div>
            </div>
         </section>
         <section class="why_section layout_padding">
                  <div class="container">
                      <div class="heading_container heading_center">
                          <h2>
                              Why Choose <span>Us</span>
                          </h2>
                      </div>
                      <div class="why_container">
                          <div class="box">
                              <div class="img-box">
                                  <img src="images/w1.png" alt="" />
                              </div>
                              <div class="detail-box">
                                  <h5>
                                      Innovation
                                  </h5>
                                  <p>
                                      As a startup, we thrive on innovation. We are constantly pushing the boundaries of what's possible, leveraging emerging technologies and creative solutions to deliver value that exceeds expectations.
                                  </p>
                              </div>
                          </div>
                          <div class="box">
                              <div class="img-box">
                                  <img src="images/w2.png" alt="" />
                              </div>
                              <div class="detail-box">
                                  <h5>
                                      Agility
                                  </h5>
                                  <p>
                                      Being a startup allows us to be agile and responsive to the evolving needs of our clients. We embrace change and adapt quickly to deliver solutions that are tailored to the unique challenges and opportunities faced by each business.
                                  </p>
                              </div>
                          </div>
                          <div class="box">
                              <div class="img-box">
                                  <img src="images/w3.png" alt="" />
                              </div>
                              <div class="detail-box">
                                  <h5>
                                      Passion
                                  </h5>
                                  <p>
                                      Our team is fueled by passion and a relentless drive to make a difference. We are committed to going above and beyond to ensure the success of our clients, driven by a genuine desire to see them thrive.
                                  </p>
                              </div>
                          </div>
                          <div class="box">
                              <div class="img-box">
                                  <img src="images/w4.png" alt="" />
                              </div>
                              <div class="detail-box">
                                  <h5>
                                      Personalized Service
                                  </h5>
                                  <p>
                                      At TaraCode, we believe in the power of personalized service. We take the time to truly understand our clients' needs and aspirations, forging deep and meaningful partnerships that extend far beyond a typical client-vendor relationship.
                                  </p>
                              </div>
                          </div>
                      </div>
                      
                  </div>
              </section>
        </div>
    );
  }
}
