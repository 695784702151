import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
      return (
      <div>
        <section class="slider_section ">
                <div id="customCarousel1" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        
                        <div class="carousel-item ">
                            <div class="container ">
                                <div class="row">
                                    <div class="col-md-6 ">
                                        <div class="detail-box">
                                            <h1>
                                                Inventory <br />
                                                  <span>Solutions</span>
                                            </h1>
                                            <p>
                                                  Our inventory solution, a comprehensive platform designed to streamline inventory management for businesses of all sizes. Whether you're a small startup or a large enterprise, our solution is tailored to meet your specific needs, providing you with the tools necessary to optimize your inventory processes and enhance efficiency
                                            </p>
                                            <div class="btn-box">
                                                <a href="" class="btn1">
                                                    Read More
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="img-box">
                                            <img src="images/slider-img.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <div class="carousel-item active">
                              <div class="container ">
                                  <div class="row">
                                      <div class="col-md-6 ">
                                          <div class="detail-box">
                                              <h1>
                                                  CRM <br />
                                                  <span>SOLUTIONS</span>
                                              </h1>
                                              <p>
                                                  Unlock the Power of Relationships with Our Cutting-Edge CRM Solutions!
                                              </p>
                                              <p>
                                                  Discover seamless integration, intuitive interfaces, and robust features designed to transform how you engage with your audience. Revolutionize your approach to customer management and experience the difference with TaraCode CRM.
                                              </p>
                                              <div class="btn-box">
                                                  <a href="" class="btn1">
                                                      Read More
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-md-6">
                                          <div class="img-box">
                                              <img src="images/slider-img.png" alt="" />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        <div class="carousel-item">
                            <div class="container ">
                                <div class="row">
                                    <div class="col-md-6 ">
                                        <div class="detail-box">
                                            <h1>
                                                Ecommerce <br />
                                                <span>Integration</span>
                                            </h1>
                                            <p>
                                                  In today's digital age, establishing an online presence for your business is not just beneficial; it's essential for reaching a broader audience and driving sales. eCommerce solutions play a pivotal role in enabling businesses to showcase their products or services, facilitate transactions, and provide a seamless shopping experience for customers.
                                            </p>
                                            <div class="btn-box">
                                                <a href="" class="btn1">
                                                    Read More
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="img-box">
                                            <img src="images/slider-img.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ol class="carousel-indicators">
                        <li data-target="#customCarousel1" data-slide-to="0" class="active"></li>
                        <li data-target="#customCarousel1" data-slide-to="1"></li>
                        <li data-target="#customCarousel1" data-slide-to="2"></li>
                    </ol>
                </div>
                </section>
        <section class="service_section layout_padding">
                <div class="service_container">
                    <div class="container ">
                        <div class="heading_container heading_center">
                            <h2>
                                Our <span>Services</span>
                            </h2>
                            <p>
                                  At TaraCode, we're more than just service providers, we're partners in your success. Whether you're a small startup or a large corporation, we're here to support you every step of the way. Explore our range of services below to learn more about how we can help you achieve your goals.
                            </p>
                        </div>
                        <div class="row">
                            <div class="col-md-4 ">
                                <div class="box ">
                                    <div class="img-box">
                                        <img src="images/s1.png" alt="" />
                                    </div>
                                    <div class="detail-box">
                                        <h5>
                                            Hosting
                                        </h5>
                                        <p>
                                              In today's digital age, having a robust online presence is crucial for businesses of all sizes. Whether you're launching a new website, migrating an existing one, or scaling your online presence, we're here to provide you with the perfect hosting solution tailored to your needs.
                                        </p>
                                        <a href="">
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 ">
                                <div class="box ">
                                    <div class="img-box">
                                        <img src="images/s2.png" alt="" />
                                    </div>
                                    <div class="detail-box">
                                        <h5>
                                            Maintenance
                                        </h5>
                                        <p>
                                              In today's fast-paced digital landscape, ensuring that your software systems are up-to-date, secure, and performing optimally is essential for the success of your business. That's where we come in.
                                        </p>
                                        <a href="">
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 ">
                                <div class="box ">
                                    <div class="img-box">
                                        <img src="images/s3.png" alt="" />
                                    </div>
                                    <div class="detail-box">
                                        <h5>
                                            Expert Support
                                        </h5>
                                        <p>
                                              In today's fast-paced digital landscape, encountering technical issues or facing complex problems is inevitable. That's where our team of seasoned professionals steps in to help.
                                        </p>
                                        <a href="">
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn-box">
                            <a href="">
                                View All
                            </a>
                        </div>
                    </div>
                </div>
            </section>
      </div>
    );
  }
}
