import React, { Component } from 'react';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
      return (
          <div>
            <div class="hero_bg_box">
              <div class="bg_img_box">
                <img src="images/hero-bg.png" alt="" />
              </div>
            </div>
            <header class="header_section">
                <div class="container-fluid">
                    <nav class="navbar navbar-expand-lg custom_nav-container ">
                        <a class="navbar-brand" href="index.html">
                            <span>
                                TARACODE
                            </span>
                        </a>

                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class=""> </span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a class="nav-link" href="/">Home</a>
                                </li>
                                <li class="nav-item">
                                      <a class="nav-link" href="/about"> About</a>
                                </li>
                                {/*<li class="nav-item">*/}
                                {/*      <a class="nav-link" href="/services">Solution & Services</a>*/}
                                {/*</li>*/}
                                {/*<li class="nav-item">*/}
                                {/*    <a class="nav-link" href="/contact">Contact Us</a>*/}
                                {/*</li>*/}
                                <li class="nav-item">
                                    <a class="nav-link" href="/login"> demo</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
              </header>
        </div>
    );
  }
}
